<template>
    <div>

        <div class="modal_header">
            <el-row :gutter="10">
                <el-col :xs="24" :sm="24">
                    <div class="grid-content bg-purple">
                        <div class="title_h">
                            {{ $t('message.new_type_expence') }}
                        </div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="24">
                    <div class="grid-content bg-purple-light">
                        <el-row class="button_r">
                            <el-button type="success" :loading="loadingButton" :disabled="loadingButton ? true : false"
                                @click="submit">{{ $t('message.save_and_close') }}</el-button>
                            <el-button @click="resetForm('form')">{{ $t('message.close') }}</el-button>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </div>

        <div class="row">
            <div class="col-12 mt-2">
                <div class="modal-body">
                    <el-form ref="form" status-icon :model="form" :rules="rules" :label-position="'left'">
                        <el-col :span="20">
                            <el-form-item :label="columns.name.title" prop="name">
                                <el-input v-model="form.name" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item :label="columns.amount.title">
                                <el-input type="number" v-model="form.amount"></el-input>
                            </el-form-item>
                            <el-form-item :label="columns.description.title">
                                <el-input type="textarea" :rows="4" v-model="form.description"
                                    autocomplete="off"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-form>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import form from '@/utils/mixins/form';
import {correctInputNumber} from "@/filters";
export default {
    mixins: [form],
    data() {
        return {}
    },
    created() { },
    computed: {
        ...mapGetters({
            rules: 'costs/rules',
            model: 'costs/model',
            columns: 'costs/columns'
        })
    },
    watch: {
        // 'form.amount': function(newPrice) {
        //     this.correctInputNumber('form', 'amount', newPrice);
        // },
    },
    methods: {
        ...mapActions({
            save: 'costs/store',
        }),
        // correctInputNumber,
        submit(close = true) {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.save(this.form)
                        .then(res => {
                            this.loadingButton = false;
                            this.$notify({
                                title: 'Ошибка',
                                type: "error",
                                offset: 130,
                                message: res.message
                            });
                            this.$emit("selected_id", res);
                            this.resetForm('form');
                        })
                        .catch(err => {
                            this.loadingButton = false;
                            this.$notify({
                                title: 'Ошибка',
                                type: "error",
                                offset: 130,
                                message: err.error.message
                            });
                        });
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.$emit('c-close', { drawer: 'drawerCreate' });
        },
    }
}
</script>
