<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div class="
          col-7
          p-0
          align-self-center
          font-weight-bold
          d-flex
          align-items-center
          w-reset
          mb-sm-2
          w-reset
          mb-sm-2
        ">
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.payments_for_expenses") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input size="mini" :placeholder="$t('message.search')" prefix-icon="el-icon-search"
            v-model="filterForm.search" clearable></el-input>
          <el-date-picker size="mini" v-model="filterForm.start_date" type="date" :format="'dd.MM.yyyy'"
            :value-format="'dd.MM.yyyy'" :placeholder="$t('message.start_date')"></el-date-picker>
          <el-date-picker size="mini" v-model="filterForm.end_date" type="date" :format="'dd.MM.yyyy'"
            :value-format="'dd.MM.yyyy'" :placeholder="$t('message.end_date')"></el-date-picker>
        </div>
      </div>
      <div class="
          col-5
          align-items-center align-self-center
          text-right
          pr-0
          d-flex
          justify-content-end
          w-reset
          w-reset
        ">
        <el-button v-can="'costs.create'" size="mini" @click="drawerCreate = true">
          <i class="el-icon-circle-plus-outline"></i> {{ $t("message.create") }}
        </el-button>
        <export-excel v-can="'costs.excel'" class="btn excel_btn" :data="list" :fields="excel_fields"
          @fetch="controlExcelData()" worksheet="Платежи за расходы " name="Платежи_за_расходы.xls">
          <el-button size="mini">
            <i class="el-icon-document-delete"></i> Excel
          </el-button>
        </export-excel>
        <crm-column-settings :columns="columns" :modelName="'costTransactions'"
          @c-change="updateColumn"></crm-column-settings>
      </div>
    </div>
    <table class="table table-bordered table-hover" v-loading="loadingData">
      <crm-pagination :pagination="pagination" @c-change="updatePagination"></crm-pagination>
      <thead>
        <tr>
          <crm-th :column="columns.id" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.transactionable_id" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.payment_type_id" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.price" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.currency_id" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.rate" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.user_id" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.date" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.comment" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :sort="sort" :column="columns.updated_at" @c-change="updateSort"></crm-th>
          <crm-th :sort="sort" :column="columns.created_at" @c-change="updateSort"></crm-th>
          <crm-th :sort="sort" :column="columns.settings" @c-change="updateSort"></crm-th>
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input clearable size="mini" class="id_input" v-model="filterForm.id"
              :placeholder="columns.id.title"></el-input>
          </th>

          <th v-if="columns.transactionable_id.show">
            <el-input clearable size="mini" v-model="filterForm.cost_transactionable"
              :placeholder="columns.transactionable_id.title"></el-input>
            <!-- <el-select
              filterable
              clearable
              :placeholder="columns.transactionable_id.title"
              size="mini"
              v-model="filterForm.transactionable_id"
            >
              <el-option
                v-for="item in costs"
                :key="item.name + item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select> -->
          </th>

          <th v-if="columns.payment_type_id.show">
            <el-select filterable clearable :placeholder="columns.payment_type_id.title" size="mini"
              v-model="filterForm.payment_type_id">
              <el-option v-for="item in payment_types" :key="item.name + item.id" :label="item.name"
                :value="item.id"></el-option>
            </el-select>
          </th>

          <th v-if="columns.price.show">
            <el-input size="mini" v-model="filterForm.price" :placeholder="columns.price.title" clearable>
            </el-input>
          </th>

          <th v-if="columns.currency_id.show">
            <el-select filterable clearable :placeholder="columns.currency_id.title" size="mini"
              v-model="filterForm.currency_id">
              <el-option v-for="item in currencies" :key="item.name + item.id" :label="item.name"
                :value="item.id"></el-option>
            </el-select>
          </th>

          <th v-if="columns.rate.show">
            <el-input size="mini" v-model="filterForm.rate" :placeholder="columns.rate.title" clearable>
            </el-input>
          </th>

          <th v-if="columns.user_id.show">
            <el-select filterable clearable :placeholder="columns.user_id.title" size="mini" v-model="filterForm.user_id">
              <el-option v-for="item in users" :key="item.name + item.id" :label="item.surname + ' ' + item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </th>

          <th v-if="columns.date.show">
            <el-date-picker :format="'dd.MM.yyyy'" :value-format="'dd.MM.yyyy'" v-model="filterForm.date"
              :placeholder="columns.date.title" size="mini">
            </el-date-picker>
          </th>

          <th v-if="columns.comment.show">
            <el-input size="mini" v-model="filterForm.comment" :placeholder="columns.comment.title" clearable>
            </el-input>
          </th>

          <th v-if="columns.updated_at.show">
            <el-date-picker :format="'dd.MM.yyyy'" :value-format="'dd.MM.yyyy'" v-model="filterForm.updated_at"
              :placeholder="columns.updated_at.title" size="mini">
            </el-date-picker>
          </th>
          <th v-if="columns.created_at.show">
            <el-date-picker :format="'dd.MM.yyyy'" :value-format="'dd.MM.yyyy'" v-model="filterForm.created_at"
              :placeholder="columns.created_at.title" size="mini">
            </el-date-picker>
          </th>
          <th v-if="columns.settings.show"></th>
        </tr>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr v-for="costTransaction in list" :key="costTransaction.id">
          <td v-if="columns.id.show">{{ costTransaction.id }}</td>
          <td v-if="columns.transactionable_id.show">
            {{
              costTransaction.transactionable
              ? costTransaction.transactionable.name
              : ""
            }}
          </td>
          <td v-if="columns.payment_type_id.show">
            {{
              costTransaction.payment_type
              ? costTransaction.payment_type.name
              : ""
            }}
          </td>
          <td v-if="columns.price.show">
            {{ (costTransaction.price / costTransaction.rate) | formatMoney }}
            <template v-if="costTransaction.rate > 1">({{ costTransaction.price | formatMoney }})</template>
          </td>
          <td v-if="columns.currency_id.show">
            {{ costTransaction.currency ? costTransaction.currency.name : "" }}
          </td>
          <td v-if="columns.rate.show">
            {{ costTransaction.rate | formatMoney }}
          </td>
          <td v-if="columns.user_id.show">
            {{ costTransaction.user ? (costTransaction.user.surname + ' ' + costTransaction.user.name) : "" }}
          </td>
          <td v-if="columns.date.show">{{ costTransaction.date }}</td>
          <td v-if="columns.comment.show">{{ costTransaction.comment }}</td>
          <td v-if="columns.updated_at.show">
            {{ costTransaction.updated_at }}
          </td>
          <td v-if="columns.created_at.show">
            {{ costTransaction.created_at }}
          </td>
          <td v-if="columns.settings.show" class="settings-td">
            <crm-setting-dropdown :model="costTransaction" name="costs" :actions="actions" @edit="edit"
              @delete="destroyModel">
            </crm-setting-dropdown>
          </td>
        </tr>
      </transition-group>
    </table>
    <el-drawer :visible.sync="drawerCreate" size="60%" :wrapperClosable="false" :drawer="drawerCreate"
      @close="reloadIfChanged" class="mytab_IdP">
      <div>
        <crm-create :reloadModel="reopenUpdate" @c-close="reloadIfChanged('drawerCreate')"></crm-create>
      </div>
    </el-drawer>
    <el-drawer :visible.sync="drawerUpdate" size="60%" :wrapperClosable="false" :drawer="drawerUpdate"
      @open="reopenUpdate = true" @close="reloadIfChanged('reopenUpdate')" @closed="emptyModel" class="mytab_IdP">
      <div>
        <crm-update :reloadModel="reopenUpdate" @open="reopenUpdate = true" @c-close="closeDrawer"></crm-update>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import list from "@/utils/mixins/list";
export default {
  mixins: [list],
  name: "CostTransactionsList",
  components: {
    CrmUpdate,
    CrmCreate,
  },
  data() {
    return {
      drawerCreate: false,
      drawerUpdate: false,
      reopenUpdate: false,
      excel_data: [],
      excel_fields: {},
      loadingData: false,
    };
  },
  computed: {
    ...mapGetters({
      list: "costTransactions/list",
      columns: "costTransactions/columns",
      pagination: "costTransactions/pagination",
      filter: "costTransactions/filter",
      sort: "costTransactions/sort",
      costs: "costs/list",
      payment_types: "paymentTypes/list",
      currencies: "currencies/list",
      users: "users/list",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async created() { },
  async mounted() {
    this.controlExcelData();

    if (this.costs && this.costs.length === 0) await this.loadCosts();
    if (this.payment_types && this.payment_types.length === 0)
      await this.loadPaymentTypes();
    if (this.currencies && this.currencies.length === 0)
      await this.loadCurrencies();
    if (this.users && this.users.length === 0) await this.loadUsers();
  },
  watch: {
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateList: "costTransactions/index",
      updateSort: "costTransactions/updateSort",
      updateFilter: "costTransactions/updateFilter",
      updateColumn: "costTransactions/updateColumn",
      updatePagination: "costTransactions/updatePagination",

      loadCosts: "costs/index",
      loadPaymentTypes: "paymentTypes/index",
      loadCurrencies: "currencies/index",
      loadUsers: "users/index",

      editModel: "costTransactions/show",
      empty: "costTransactions/empty",
      delete: "costTransactions/destroy",
      refreshData: "costTransactions/refreshData",
    }),
    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => { });
    },
    async edit(model) {
      await this.editModel(model.id)
        .then(async (res) => {
          this.drawerUpdate = true;
        })
        .catch((err) => { });
    },
    async show(model) {
      await this.showModel(model.id)
        .then((res) => {
          this.drawerShow = true;
        })
        .catch((err) => { });
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            switch (column.column) {
              case "transactionable_id":
                this.excel_fields[column.title] = "transactionable.name";
                break;
              case "payment_type_id":
                this.excel_fields[column.title] = "payment_type.name";
                break;
              case "currency_id":
                this.excel_fields[column.title] = "currency.name";
                break;
              case "user_id":
                this.excel_fields[column.title] = "user.name";
                break;
              default:
                this.excel_fields[column.title] = column.column;
            }
          }
        }
      }
    },
    destroyModel(model) {
      this.delete(model.id)
        .then((res) => {
          this.$notify({
            title: 'Ошибка',
            type: "error",
            offset: 130,
            message: res.message
          });
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
